import i18n from "i18next";
import translationEN from "./languages/en/translationEN.json";
import dataEN from "./languages/en/data.json";
import translationFR from "./languages/fr/translationFR.json";
import dataFR from "./languages/fr/data.json";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { DOMAIN_LANGUAGE } from "./utils/configConstants";

/**  for init the component it is necessary to have the order in the instance of LanguageDetector and InitReactI18Next
 Ex.
 i18n
  .use(LanguageDetector) 
  .use(initReactI18next)
  */
// eslint-disable-next-line no-unused-vars
const domainDetector = {
  // We use the name to refer to it later when we want to tell i18next when to use it.
  name: "domain",
  lookup(req, res, options) {
    // You need to either set the value of DEFAULT_LOCALE before this or hard-code the value here.
    let locale = "en";
    // In the browser, get the hostname from window.location.
    if (typeof window !== "undefined") {
      locale = DOMAIN_LANGUAGE[window.location.hostname];
    }
    // On the server, get the hostname from the request headers.
    // We use the host header which is available on IncomingMessage.
    // https://nodejs.org/api/http.html#http_class_http_incomingmessage
    // But the host header may include the port so first we take that off, if it exists.
    else {
      const hostname = req.headers.host?.split(":")[0];
      locale = DOMAIN_LANGUAGE[hostname];
    }
    return locale;
  },
};

i18n
  .use(LanguageDetector) // passes i18n down to react-i18next
  .use(initReactI18next) // passes i18n down to react-i18next)
  .init({
    detection: {
      // customDetectors: [domainDetector],

      load: "languageOnly",
      // keys or params to lookup language from
      lookupQuerystring: "lng",
      lookupCookie: "i18next",
      lookupLocalStorage: "i18nextLng",
      lookupSessionStorage: "i18nextLng",
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,
      // order and from where user language should be detected
      order: [
        "querystring",
        "navigator",
        // "cookie",
        // "localStorage",
        // "sessionStorage",
        // "htmlTag",
        // "path",
        // "subdomain",
      ],
      // cache user language on
      // caches: ["localStorage", "cookie"],
      // excludeCacheFor: ["cimode"], // languages to not persist (cookie, localStorage)

      // // optional expire and domain for set cookie
      // cookieMinutes: 10,
      // // cookieDomain: "myDomain",

      // optional htmlTag with lang attribute, the default is:
      // htmlTag: document.documentElement,

      // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
      // cookieOptions: { path: "/", sameSite: "strict" },
    },
    resources: {
      en: {
        translation: translationEN,
        data: dataEN,
      },
      "en-US": {
        translation: translationEN,
        data: dataEN,
      },
      fr: {
        translation: translationFR,
        data: dataFR,
      },
      "fr-CA": {
        translation: translationFR,
        data: dataFR,
      },
    },
    //lng: "en",
    //lng: navigator.language || navigator.userLanguage,
    fallbackLng: "en",
    supportedLngs: ["en", "en-US", "fr", "fr-CA"],
    interpolation: {
      escapeValue: true,
    },
  });

export default i18n;

export function getExtraDataByLanguage() {
  const data = i18n.getDataByLanguage(i18n.language).data;
  if (data) return data;
  return null;
}
