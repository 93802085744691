import React from "react";
import ReactPlayer from "react-player/lazy";
import PropTypes from "prop-types";

/** Simple component to show a video or list of videos
 * parameters
 * Title: this component recieve the title of the header
 * autoPlaying: if we want the video to play when loading the page, default = false
 * videoList: array of string with the URL's videos
 *  Examples:
 *      videoList = ["https://cdn.shopify.com/s/files/1/0162/3841/1830/files/how-it-works-edited_1.mp4?v=1601323279"]
 *      videoList = ["urlVideo1","urlVideo2","urlVideo3"....]
 */

const VideoPlayer = ({
  title,
  autoPlaying = false,
  videoList,
  titleCssClass,
  light = false,
}) => {
  return (
    <>
      {title && (
        <div className={`bullseye-red h4 ${titleCssClass}`}>{title}</div>
      )}
      <ReactPlayer
        url={videoList}
        controls={true}
        width={"100%"}
        height={"100%"}
        playing={autoPlaying}
        light={light}
      />
    </>
  );
};
VideoPlayer.propTypes = {
  /** Title to display on the header component */
  title: PropTypes.string.isRequired,
  /** playing on the page didmount */
  autoPlaying: PropTypes.bool,
  /** array of strings with the URL of the videos, at least one video should come */
  videoList: PropTypes.array.isRequired,
};
export default VideoPlayer;
