import React from "react";

const Separator = ({ label }) => {
  return (
    <div className="d-block">
      <div className="h5">{label}</div>
      <hr className="mt-2 mb-3" />
    </div>
  );
};
export default Separator;
