import React from "react";
import PropTypes from "prop-types";
import TextInput from "./textInput";
import DropDownInput from "./dropDownInput";
import MultiOptionsInput from "./multiOptionsInput";
import CommentInput from "./commentInput";
import NameInput from "./nameInput";
import AddressInput from "./addressInput";
import RateInput from "./rateInput/rateInput";
import RateServiceInput from "./rateServiceInput/rateServiceInput";
import Separator from "./separator/separator";
import DateTimeInput from "./dateTimeInput/dateTimeInput";
import DateTimeRange from "./dateTimeRange/dateTimeRange";
import PasswordInput from "./passwordInput/passwordInput";
import AutocompleteInput2 from "./autocompleteInput/autocompleteInput2";

const DynamicInputSelector = ({
  name,
  label,
  inputOptionName,
  required,
  compact,
  small,
  register,
  watch,
  control,
  errors,
  labelNote,
  placeholder,
  onChange,
  options = [],
  validations,
  countriesAndStatesConfig,
  ...props
}) => {
  let inputComponent;
  switch (inputOptionName.toLowerCase()) {
    case "autocomplete":
      inputComponent = (
        <AutocompleteInput2
          name={name}
          label={label}
          compact={compact}
          small={small}
          type={inputOptionName === "Numeric" ? "number" : "text"}
          register={register(validations)}
          control={control}
          required={required}
          errors={errors}
          onChange={onChange}
          options={options}
          placeholder={placeholder}
          {...props}
        />
      );

      break;
    case "password":
      inputComponent = (
        <PasswordInput
          name={name}
          label={label}
          compact={compact}
          small={small}
          errors={errors}
          register={register}
          onChange={onChange}
          required={required}
          validations={validations}
          {...props}
        />
      );
      break;
    case "datetimerange":
      inputComponent = (
        <DateTimeRange
          name={name}
          label={label}
          compact={compact}
          small={small}
          errors={errors}
          register={register}
          watch={watch}
          onChange={onChange}
          required={required}
          validations={validations}
          labelNote={labelNote}
          {...props}
        />
      );
      break;
    case "datetime":
      inputComponent = (
        <DateTimeInput
          name={name}
          label={label}
          compact={compact}
          small={small}
          errors={errors}
          register={register(validations)}
          onChange={onChange}
          required={required}
          {...props}
        />
      );
      break;
    case "separator":
      inputComponent = <Separator label={label} />;
      break;
    case "rateservice":
      inputComponent = (
        <RateServiceInput
          name={name}
          label={label}
          compact={compact}
          small={small}
          register={register(validations)}
          required={required}
          errors={errors}
          onChange={onChange}
          {...props}
        />
      );
      break;
    case "rate":
      inputComponent = (
        <RateInput
          name={name}
          label={label}
          compact={compact}
          small={small}
          register={register(validations)}
          required={required}
          errors={errors}
          onChange={onChange}
          {...props}
        />
      );
      break;
    case "name":
      inputComponent = (
        <NameInput
          name={name}
          label={label}
          compact={compact}
          small={small}
          register={register}
          required={required}
          errors={errors}
          onChange={onChange}
          validations={validations}
          {...props}
        />
      );
      break;
    case "text":
    case "email":
    case "numeric":
    case "street_address":
    case "address_line_2":
    case "state":
    case "city":
    case "short_address":
    case "company":
    case "phonenumber":
      inputComponent = (
        <TextInput
          name={name}
          label={label}
          compact={compact}
          small={small}
          type={inputOptionName === "Numeric" ? "number" : "text"}
          register={register(validations)}
          required={required}
          errors={errors}
          onChange={onChange}
          {...props}
        />
      );

      break;
    case "comments":
      inputComponent = (
        <CommentInput
          name={name}
          label={label}
          compact={compact}
          small={small}
          register={register(validations)}
          required={required}
          errors={errors}
          labelNote={labelNote}
          onChange={onChange}
          placeholder={placeholder}
          {...props}
        />
      );

      break;
    case "address":
      inputComponent = (
        <AddressInput
          name={name}
          label={label}
          compact={compact}
          small={small}
          register={register}
          required={required}
          errors={errors}
          onChange={onChange}
          countriesAndStatesConfig={countriesAndStatesConfig}
          {...props}
        />
      );
      break;

    case "dropdown":
      inputComponent = (
        <DropDownInput
          label={label}
          name={name}
          small={small}
          options={options}
          register={register(validations)}
          required={required}
          errors={errors}
          onChange={onChange}
          {...props}
        />
      );
      break;
    case "yesno":
      let optionYesNo = [
        { id: "true", name: "Yes" },
        { id: "false", name: "No" },
      ];
      inputComponent = (
        <MultiOptionsInput
          label={label}
          name={name}
          options={optionYesNo}
          register={register(validations)}
          required={required}
          type={"radio"}
          errors={errors}
          {...props}
        />
      );
      break;
    case "radiogroup":
    case "checkboxlist":
      inputComponent = (
        <MultiOptionsInput
          label={label}
          name={name}
          options={options}
          register={register(validations)}
          required={required}
          type={inputOptionName === "checkboxlist" ? "checkbox" : "radio"}
          errors={errors}
          {...props}
        />
      );
      break;
    default:
      inputComponent = <div> input no definido {inputOptionName} </div>;
      break;
  }

  return inputComponent;
};

DynamicInputSelector.propTypes = {
  /** string with the type of the optio input to show */
  inputOptionName: function (props, propName, componentName) {
    if (
      ![
        "name",
        "radiogroup",
        "checkboxlist",
        "dropdown",
        "phone",
        "address",
        "name",
        "text",
        "email",
        "numeric",
        "categories",
        "street_address",
        "address_line_2",
        "state",
        "city",
        "short_address",
        "company",
        "phonenumber",
        "yesno",
        "comments",
        "separator",
        "dateTime",
        "datetimerange",
        "password",
        "autocomplete",
      ].includes(props[propName].toLowerCase())
    ) {
      return new Error(
        "Invalid prop `" +
          propName +
          "` supplied to" +
          " `" +
          componentName +
          "`. Validation failed."
      );
    }
  },

  /** string of the Input Label */
  label: PropTypes.string.isRequired,
  /** name of the controll input */
  name: PropTypes.string.isRequired,

  /** text to put into the input like placeholder */
  placeholder: PropTypes.string,

  /** options array with the options to select */
  options: PropTypes.array,
  /** Parameter  */
  required: PropTypes.bool,
  /** Function to controll the state of the inputs */
  register: PropTypes.func.isRequired,
  /** string to display errors */
  errors: PropTypes.object,
  /** default value */
  value: PropTypes.string,

  props: PropTypes.object,
};
export default DynamicInputSelector;
