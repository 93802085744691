import "./locationSelector.scss";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";

/**
 * Location Selector component.
 *
 * Allows choosing a location to edit.
 *
 * @param {Object} props
 * @param props.locationList A list of locations to be shown.
 * @param props.onSelect Callback function if a location has been selected.
 * @param props.onCreateNew Callback function a user choosen to create a new location.
 * @returns JSX
 */
const LocationSelector = (props) => {
  const { t } = useTranslation();

  return (
    <div className="location-selector">
      <div className="bullseye-red h4 mt-3 mb-4 text-center">{ t('locationSelector.title') }</div>
      <div className="intro text-center mt-3 mb-4 font-weight-bold">{ t('locationSelector.intro') }</div>
      <div className="text-center">
        <Button
          className="btn-danger mb-4 px-3 py-1"
          variant="secondary"
          type="submit"
          onClick={props.onCreateNew}
        >
          { t('locationSelector.createNew') }
        </Button>
      </div>
      {props.locationList.map((l) =>
        <div className="loaction-selector__item" key={l.id}>
          <div className="details">
            <div className="name">{ l.name }</div>
            <div className="address">{l.address1}, {l.city}, {l.stateAbbr} {l.postCode}, { l.countryCode }</div>
          </div>
          <Button
            className="btn-danger px-3"
            variant="secondary"
            size="sm"
            type="submit"
            onClick={() => props.onSelect(l.id)}
          >
            { t('locationSelector.editAndResubmit') }
          </Button>
        </div>
      )}
    </div>
  );
};

export default LocationSelector;
