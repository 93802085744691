import React from "react";
import PropTypes from "prop-types";
import "./showcase.scss";

/**
 * Showcase Card with an icon on the top, a title below and paragraph
 */
const ShowcaseCard = ({ icon, title, body, links, itemCssClass }) => {
  const iconIsSpriteSvg = icon && icon.includes(".svg#");

  return (
    <div className={`m-1 showcase__card--maxSize ${itemCssClass || ""}`}>
      {!iconIsSpriteSvg && (
        <img
          className="my-3 mx-auto d-block"
          src={icon}
          alt={title || "showcase"}
        />
      )}
      {iconIsSpriteSvg && (
        <svg className="my-3 d-block mx-auto nohover showcase__widthSquare100">
          <use href={icon} />
        </svg>
      )}

      <strong>{title}</strong>
      <p>{body}</p>
      <ul className="list-unstyled">
        {links &&
          links.length > 0 &&
          links.map((link) => {
            return (
              <li key={link.url}>
                <a href={link.url}>{link.title}</a>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

ShowcaseCard.propTypes = {
  /** icon URL */
  icon: PropTypes.string,
  /** title */
  title: PropTypes.string,
  /** description in a paragraph */
  body: PropTypes.string.isRequired,
};

export default ShowcaseCard;
