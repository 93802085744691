import React from "react";
import PropTypes from "prop-types";
/**
 * Component to display a label with one boostrap Icon
 */
const LabelWithIcon = ({
  btIcon: { btIconName, size },
  title,
  url,
  containerClass = "",
  labelClass = "",
}) => {
  return (
    <div className={containerClass}>
      <div className="d-inline-block">
        <svg className="mr-2" width={size} height={size}>
          <use
            xlinkHref={`/images/b-icons/bootstrap-icons.svg#${btIconName}`}
          />
        </svg>
      </div>
      <span className={"align-middle " + labelClass}>
        {url ? <a href={url}>{title}</a> : title}
      </span>
    </div>
  );
};
LabelWithIcon.propTypes = {
  /** Object with information about the icon */
  btIcon: PropTypes.shape({
    /** Boostrap name of the icon */
    btIconName: PropTypes.string.isRequired,
    /** size of the icon */
    size: PropTypes.number.isRequired,
  }),
  /** label title of the card */
  title: PropTypes.string.isRequired,
  /** url address to the click */
  url: PropTypes.string,
  /** css class for the container */
  containerClass: PropTypes.string,
  /** css class for the label */
  labelClass: PropTypes.string,
};

export default LabelWithIcon;
