import React from "react";
import PropTypes from "prop-types";
import Address from "../address";
import "./nearbyLocations.scss";
import { useTranslation } from "react-i18next";

/** Component to display the nearly locations.
 *  params: Title-> title to display.
 *          nearbyLocationsList-> list of locations to display
 *          information per location necessary to this component
 *
 *          -Address1
 *          -City
 *          -State
 *          -Zip Code
 *          -Distance
 *
 */
const NearbyLocations = ({
  title,
  nearbyLocationsList,
  customPin,
  maxDisplayed = 3,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="bullseye-red h4">{title}</div>
      <div className="d-flex flex-wrap">
        {nearbyLocationsList?.slice(0, maxDisplayed).map((location) => {
          return (
            <div
              key={location.Id}
              className="nearbyLocations__card--maxSize my-2 d-flex border-bottom"
            >
              <div className="float-left mt-n2">
                <img
                  className="mx-auto p-2 d-block"
                  src={customPin ?? "/images/beIcon.png"}
                  alt={location.Name}
                  style={{ maxHeight: "40px" }}
                />
              </div>
              <div>
                <strong>{location.Name}</strong>
                <Address
                  address1={location.Address1}
                  city={location.City}
                  stateAbbr={location.State}
                  postalCode={location.PostCode}
                  country={location.CountryName}
                  shortAddress={true}
                ></Address>
                <p className="font-italic text-muted">
                  {t("nearbyCelebrations.kilometersAway", {
                    distance: location.Distance ?? 0,
                  })}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

NearbyLocations.propTypes = {
  /** Title component  */
  title: PropTypes.string.isRequired,

  nearbyLocationsList: PropTypes.arrayOf(
    /** Object with the location information */
    PropTypes.shape({
      Address1: PropTypes.string.isRequired,
      City: PropTypes.string.isRequired,
      StateAbbr: PropTypes.string,
      PostalCode: PropTypes.string,
      Name: PropTypes.string.isRequired,
      Distance: PropTypes.number.isRequired,
    })
  ),
  /** If present the control will replace the default for this url */
  customPin: PropTypes.string,
};

export default NearbyLocations;
