import React from "react";
//import "google-maps-reviews/google-maps-reviews.css";
import Reviews from "../reviews/reviews";
import PropTypes from "prop-types";
import {
  getDaysAgoFromToday,
  sortArrayReviewsByDateDesc,
} from "../../utils/utils";
/**
 * BullsEye Reviews Components, shows all the reviews from a location
 */
const BullseyeReviews = ({ title, reviews, onSubmit, reviewConfiguration }) => {
  let sumRating = reviews.reduce(function (prev, cur) {
    return prev + cur.OverallRating;
  }, 0);

  reviews = sortArrayReviewsByDateDesc(reviews);
  let listReviews = {
    rating: reviews.length > 0 ? parseInt(sumRating / reviews.length) : 0,
    countReviews: reviews.length,
    reviews: reviews.map((review) => {
      return {
        author_name: review.FirstName + " " + review.LastName,
        rating: review.OverallRating,
        relative_time_description: getDaysAgoFromToday(review.DateCreated),
        text: review.Comments,
        services: [
          { name: "Professionalism", rating: review.RatingCategoryOne },
          { name: "Product Knowledge", rating: review.RatingCategoryTwo },
          { name: "Service", rating: review.RatingCategoryThree },
        ],
      };
    }),
  };

  return (
    <>
      <Reviews
        title={title}
        listReviews={listReviews}
        isBullseyeReviews={true}
        onSubmit={(data) => onSubmit(data)}
        reviewConfiguration={reviewConfiguration}
      />
    </>
  );
};
BullseyeReviews.propTypes = {
  /** label title to the card */
  title: PropTypes.string.isRequired,
  /** list of bullEye reviews */
  //reviews: PropTypes.string.isRequired,
  /** function onSubmit data when is writen a new review */
  onSubmit: PropTypes.func.isRequired,
};
export default BullseyeReviews;
