import React from "react";
import PropTypes from "prop-types";

const Label = ({ htmlFor, label, required, inline = false }) => {
  return (
    <label
      className="col-12 col-sm-4 col-form-label text-left text-sm-right"
      htmlFor={htmlFor}
    >
      {label} {required && <span style={{ color: "red" }}>*</span>}
    </label>
  );
};

Label.propTypes = {
  /** HTML ID for associated input */
  htmlFor: PropTypes.string.isRequired,

  /** Label text */
  label: PropTypes.string.isRequired,

  /** Display asterisk after label if true */
  required: PropTypes.bool,
};

export default Label;
