import React, { useEffect, useState } from "react";
import "google-maps-reviews/google-maps-reviews.css";
import Reviews from "../reviews";
import PropTypes from "prop-types";
/**
 * Google Reviews Compoents, shows all the reviews from a location with his google key
 */
const GoogleReviews = ({
  title,
  GoogleplaceId,
  googleScriptsReady = false,
}) => {
  //
  const [reviews, setReviews] = useState({
    rating: 5,
    countReviews: 0,
    reviews: [],
    status: false,
    urlMoreReviews: "#",
  });

  var callback = function (place, status) {
    // eslint-disable-next-line no-undef
    if (status === google.maps.places.PlacesServiceStatus.OK) {
      const reviews = {
        rating: place.rating,
        countReviews: place.reviews.length,
        reviews: place.reviews,
        status: true,
        urlMoreReviews: place.url,
      };

      setReviews(reviews);
    }
  };

  useEffect(() => {
    if (!googleScriptsReady) return;
    // initiate a Google Places Object
    const mydiv = document.getElementById("bu-google-reviews");

    // eslint-disable-next-line no-undef
    var service = new google.maps.places.PlacesService(mydiv);

    const request = {
      placeId: GoogleplaceId,
    };

    service.getDetails(request, callback);
  }, [GoogleplaceId, googleScriptsReady]);

  return (
    <>
      <div id="bu-google-reviews" className="d-none"></div>
      {reviews.status && googleScriptsReady && (
        <Reviews title={title} listReviews={reviews} />
      )}
    </>
  );
};
GoogleReviews.propTypes = {
  /** label title to the card */
  title: PropTypes.string.isRequired,
  /** Google Place Id for the location */
  GoogleplaceId: PropTypes.string.isRequired,
  /** Prevent loading Google Reviews without Google SDK script ready  */
  googleScriptsReady: PropTypes.bool.isRequired,
};
export default GoogleReviews;
