import React, { useState } from "react";
import { useAccordionToggle } from "react-bootstrap";

const AccordionCustomToggle = ({ children, eventKey }) => {
  const [caretState, setCaretState] = useState("close");

  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    setCaretState(caretState === "open" ? "close" : "open");
    return null;
  });

  return (
    <div
      role="button"
      onClick={decoratedOnClick}
      className={
        "w-100 py-2 bullseye-red right-caret right-caret--" + caretState
      }
      style={{ cursor: "pointer" }}
    >
      {children}
    </div>
  );
};

export default AccordionCustomToggle;
