import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { BullseyeContext } from "../../app2";
import { findClientMappingSettings } from "../../services/restSearchService";
import LandingPage from "./landingPage";
const GetParameterPage = (props) => {
  const { landingPageConfiguration, locatorConfiguration } = useContext(BullseyeContext);
  
  const [googleMapKey, setGoogleMapKey] = useState({
    loading: true,
    apiKey: null,
  });

  const { interfaceName } = useParams();

  useEffect(() => {
    const getGoogleMapKey = async () => {
      const response = await findClientMappingSettings(
        locatorConfiguration.clientId,
        locatorConfiguration.apiKey
      );
      setGoogleMapKey({
        loading: false,
        apiKey: response.MappingSettings[0].APIKey,
      });
    };

    locatorConfiguration && getGoogleMapKey();
  }, [locatorConfiguration]);

  return (
    landingPageConfiguration !== null &&
    locatorConfiguration !== null &&
    !googleMapKey.loading && (
      <>
        <Helmet>
          <link
            rel="stylesheet"
            type="text/css"
            href={`https://app2.bullseyelocations-staging.com/resources/${landingPageConfiguration.clientId}/landing/${interfaceName}.min.css`}
          />
        </Helmet>

        <LandingPage />
      </>
    )
  );
};

export default GetParameterPage;
