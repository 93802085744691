import React from "react";
import PropTypes from "prop-types";

/** Review list component, display a cards list with the comments and reviews made it by users  */
const Testimonials = ({ title, testimonials }) => {
  return (
    <div>
      {title && <div className="bullseye-red h4">{title}</div>}
      {testimonials && testimonials.length > 0 && (
        <div className="px-2 mx-2">
          {testimonials.map((testimonial) => {
            return (
              <blockquote key={testimonial.id} className="blockquote my-5">
                <p className="mb-0">{testimonial.description}</p>
                <footer className="blockquote-footer text-right">
                  {testimonial.author}
                </footer>
              </blockquote>
            );
          })}
        </div>
      )}
    </div>
  );
};
Testimonials.propTypes = {
  /** Text label to show at the card */
  title: PropTypes.string.isRequired,
  /** List of testimony to display */
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      /** id of the testimony */
      id: PropTypes.string.isRequired,
      /** Testimonial description */
      description: PropTypes.string.isRequired,
      /** Author of the testimony*/
      author: PropTypes.string.isRequired,
    })
  ).isRequired,
};
export default Testimonials;
