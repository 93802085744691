import { useState } from "react";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { AuthenticateUser } from "../../services/restUserService";
import TextInput from "../dynamicForm/textInput/textInput";
import { useTranslation } from "react-i18next";

/**
 * Form to check username and password combination.
 *
 * @param {Object} props
 * @param props.data Default form data:
 * @param props.data.email
 * @param props.data.password
 * @param props.onLogin Callback function upon successful login.
 * @returns JSX
 */
const LoginForm = (props) => {
  const {
    register: registerInput,
    handleSubmit, 

    errors,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
  });

  const [error, setError] = useState();

  const { t } = useTranslation();

  const checkEmail = async (input) => {
    const apiResponse = await AuthenticateUser(input.email, input.password);

    switch (apiResponse.headers.errorcode) {
      case '101':
        // Successful authentication.
        props.onLogin({userId: apiResponse.headers.memberid, ...input});
        break;

      default:
        setError(apiResponse.headers.errormessage);
        break;
    }
  };

  const defaultValues = {email: null, password: null, ...props.data }
  const passwordRecoveryUrl = process.env.REACT_APP_ADMIN_URL + '/PasswordRecovery.aspx';

  return (
    <form
      onSubmit={handleSubmit(checkEmail)}
      className="login-form col-sm-6 offset-sm-3 col-md-4 offset-md-4"
    >
      <div className="text-center my-3 font-weight-bold">
        { t('loginForm.label') }
      </div>
      {error && <div className="alert alert-danger my-3">{ t(error) }</div>}
      <TextInput
        name="email"
        label={ t('loginForm.email') }
        type="email"
        required
        compact
        register={registerInput}
        errors={errors}
        onChange={null}
        defaultValue={defaultValues.email} 
      />
      <TextInput
        name="password"
        label={ t('loginForm.password') }
        type="password"
        required
        compact
        register={registerInput}
        errors={errors}
        onChange={null}
        defaultValue={defaultValues.password} 
      />
      <div className="mt-3 text-center">
        <Button className="btn-danger px-4" variant="secondary" type="submit">
          { t('loginForm.submit') }
        </Button>
      </div>
      <div className="mt-3 text-center">
        <a
          href={passwordRecoveryUrl}
          target="_blank"
          rel="noreferrer"
        >
          { t('loginForm.forgotPassword') }
        </a>
      </div>
    </form>
  );
};

export default LoginForm;
