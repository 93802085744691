import React from "react";
import PropTypes from "prop-types";
/** Component to show some list of information with or without image*/
const PhotoListWithTitle = ({ title, items, maxItemsToDisplay = 0 }) => {
  return (
    <div>
      <div className="bullseye-red h4">{title}</div>
      {items && items.length && (
        <ul className="list-unstyled">
          {items
            .slice(0, maxItemsToDisplay > 0 ? maxItemsToDisplay : items.length)
            .map((item) => (
              <li className="media py-3 border-bottom" key={item.id}>
                {item.image && (
                  <img
                    className="mr-3"
                    src={item.image}
                    alt={item.body}
                    style={{ maxWidth: "30%" }}
                  />
                )}
                <div className="media-body">
                  {item.url ? (
                    <a href={item.url}>{item.body}</a>
                  ) : (
                    <>{item.body}</>
                  )}
                </div>
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};
PhotoListWithTitle.propTypes = {
  /** Title of the card */
  title: PropTypes.string.isRequired,
  /** List of items to display */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      /** Item id */
      id: PropTypes.number.isRequired,
      /** Description of the Item */
      body: PropTypes.string.isRequired,
      /** Url image */
      image: PropTypes.string,
    })
  ).isRequired,
  /** Number of items to display on the list, if maxItemsToDisplay=0 then show all the items */
  maxItemsToDisplay: PropTypes.number,
};
export default PhotoListWithTitle;
