import http from "./httpService";

const endpointPath = "RestSearch.svc";
const categoryGetMethod = "GetCategories";
const locationGetMethod = "GetLocation";
const location2GetMethod = "GetLocation2";
const doSearch2GetMethod = "DoSearch2";
const countryListGetMethod = "GetCountryList";
const countryStatesListGetMethod = "GetStatesByCountry";
const getRegion1ListGetMethod = "GetRegion1List";
const logSearchRsltwReq = "LogSearch";
const getClientMappingSettings = "RestSearch.svc/GetClientMappingSettings";
const getLandingPageConfigurationGetMethod = "GetLandingPageConfiguration";

function getLandingPageConfigurationURL(
  interfaceName,
  location,
  interfaceId,
  landingPageId
) {
  let parameters = "";

  if (interfaceName) {
    parameters += `interfaceName=${interfaceName}`;
  } else if (interfaceId) {
    parameters += `interfaceId=${interfaceId}`;
  } else if (landingPageId) {
    parameters += `landingPageId=${landingPageId}`;
  }
  if (location) {
    parameters += `&LocationId=${location}`;
  }
  return `${endpointPath}/${getLandingPageConfigurationGetMethod}?${parameters}`;
}
export async function getLandingPageConfiguration(
  interfaceName,
  location,
  interfaceId,
  landingPageId
) {
  const config = await http.get(
    getLandingPageConfigurationURL(
      interfaceName,
      location,
      interfaceId,
      landingPageId
    ),
    { params: [] }
  );
  return config.data;
}
function categoryUrl() {
  return `${endpointPath}/${categoryGetMethod}?ReturnAllCategories=true`;
}
function locationUrl(id) {
  return `${endpointPath}/${locationGetMethod}?LocationId=${id}`;
}
function location2Url(id, thirdpartyId, landingPageId) {
  return `RestLocation.svc/${location2GetMethod}?LocationId=${id}&LandingPageID=${landingPageId}`;
}
function locationThirdpartyUrl(id) {
  return `${endpointPath}/${locationGetMethod}?ThirdPartyId=${id}`;
}
function countryListUrl() {
  return `${endpointPath}/${countryListGetMethod}`;
}
function countryStatesListUrl(countryId) {
  return `${endpointPath}/${countryStatesListGetMethod}?CountryId=${countryId}`;
}
function LogSearchRsltwReqURL(
  locationId,
  responseType,
  searchReqLogId,
  attributeId,
  attributeTypeId
) {
  let endpoint = `${endpointPath}/${logSearchRsltwReq}?locationID=${locationId}`;

  if (responseType) {
    endpoint += `&responseType=${responseType}`;
  }

  if (searchReqLogId) {
    endpoint += `&SearchLogReqId=${searchReqLogId}`;
  }

  if (attributeId) {
    endpoint += `&AttributeId=${attributeId}`;
  }

  if (attributeTypeId) {
    endpoint += `&AttributeType=${attributeTypeId}`;
  }

  return endpoint;
}

export async function getCategories() {
  http.setSecurityParameters();

  const categories = await http.get(categoryUrl());
  return categories.data;
}

export async function getSearchLocation(id, thirdPartyId) {
  http.setSecurityParameters();

  if (id) {
    const location = await http.get(locationUrl(id));
    return location.data;
  } else {
    const location = await http.get(locationThirdpartyUrl(thirdPartyId));
    return location.data;
  }
}

export async function getSearchLocation2(
  id,
  thirdpartyId,
  landingPageId,
  clientId,
  apiKey
) {
  http.setSecurityParameters2(clientId, apiKey);

  if (id) {
    const location = await http.get(
      location2Url(id, thirdpartyId, landingPageId)
    );
    return location && location.data ? location.data : null;
  }
  return null;
}

function doSearch2Url(
  countryId,
  postalCode,
  radius,
  startIndex,
  pageSize,
  latitude,
  longitude,
  state,
  city,
  categoriesIDs,
  maxResults
) {
  let endpoint = `${endpointPath}/${doSearch2GetMethod}?FillAttr=true&GetHoursForUpcomingWeek=true&Radius=${radius}&StartIndex=${startIndex}&PageSize=${pageSize}`;

  if (latitude && longitude) {
    endpoint += `&Latitude=${latitude}&Longitude=${longitude}`;
  }

  if (postalCode) {
    endpoint += `&PostalCode=${postalCode}`;
  }

  if (state) {
    endpoint += `&State=${state}`;
  }

  if (city) {
    endpoint += `&City=${city}`;
  }
  if (countryId) {
    endpoint += `&CountryId=${countryId}`;
  }
  if (categoriesIDs) {
    endpoint += `&CategoryIDs=${categoriesIDs}`;
  }
  if (maxResults) {
    endpoint += `&MaxResults=${maxResults}`;
  }

  return endpoint;
}

function getRegion1ListUrl(countryId) {
  return `${endpointPath}/${getRegion1ListGetMethod}?CountryId=${countryId}`;
}

export async function DoSearch2({
  radius,
  countryId,
  latitude,
  longitude,
  state,
  city,
  pageSize,
  zipCode,
  configClientSearch,
  categoriesIDs = "",
  maxResults,
}) {
  if (
    configClientSearch &&
    configClientSearch.apiKey &&
    configClientSearch.clientId
  )
    http.setSecurityParameters2(
      configClientSearch.clientId,
      configClientSearch.apiKey
    );
  else http.setSecurityParameters2();

  const locations = await http.get(
    doSearch2Url(
      countryId,
      zipCode,
      radius,
      0,
      pageSize,
      latitude,
      longitude,
      state,
      city,
      categoriesIDs,
      maxResults
    )
  );
  return locations && locations.data ? locations.data : [];
}

export async function GetRegion1List(countryId) {
  http.setSecurityParameters();

  const region1List = await http.get(getRegion1ListUrl(countryId));
  return region1List.data;
}

export async function GetCountryList({ clientId, apiKey }) {
  http.setSecurityParameters2(clientId, apiKey);

  const location = await http.get(countryListUrl());
  return location.data;
}
export async function GetCountryStatesList(countryId, configClientSearch) {
  http.setSecurityParameters2(
    configClientSearch.clientId,
    configClientSearch.apiKey
  );

  const location = await http.get(countryStatesListUrl(countryId));
  return location.data;
}

export async function LogSearchRsltwReq(
  locationId,
  responseType,
  searchReqLogId,
  attributeId,
  attributeTypeId
) {
  http.setSecurityParameters();
  await http.get(
    `${LogSearchRsltwReqURL(
      locationId,
      responseType,
      searchReqLogId,
      attributeId,
      attributeTypeId
    )}`
  );
}
export async function findClientMappingSettings(clientId, apiKey) {
  http.setSecurityParameters(clientId, apiKey);
  const location = await http.get(getClientMappingSettings);
  return location.data;
}

/**
 * 
 * @param {array} countryIds An array of country IDs.
 * @param {object} configClientSearch Search configuration with apiKey and cliendId. 
 * @returns Promise
 */
export async function getLocationList(countryIds, categoryIds) {
  http.setSecurityParameters2();

  const apiURL = `${endpointPath}/GetLocationList?action=json&CountryIds=${countryIds.join(',')}&CategoryIds=${categoryIds.join(',')}`;
  const { data } = await http.get(apiURL);
  const { locations } = JSON.parse(data);
  return locations;
}