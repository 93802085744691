import React, { useState } from "react";
import DynamicForm from "../dynamicForm/dynamicForm";
import { formReviewConfiguration } from "../../utils/constants";

const WriteReview = ({ title, onSubmit, reviewConfiguration }) => {
  const [isSubmited, setIsSubmited] = useState(false);
  const onSubmitReview = (data) => {
    setIsSubmited(true);
    onSubmit(data);
  };
  let formConfiguration = { fields: [] };

  function pushFieldConfiguration(field) {
    formConfiguration.fields.push(
      formReviewConfiguration.fields.find((x) => x.name === field)
    );
  }
  if (reviewConfiguration.reviewerName) {
    pushFieldConfiguration("FirstName");
    pushFieldConfiguration("LastName");
  }
  if (reviewConfiguration.overallRating)
    pushFieldConfiguration("OverallRating");
  if (reviewConfiguration.comments) pushFieldConfiguration("Comments");
  if (reviewConfiguration.recommendationQuestion)
    pushFieldConfiguration("RecommendationQuestion");

  if (reviewConfiguration.ratingCategoryOne)
    pushFieldConfiguration("RatingCategoryOne");
  if (reviewConfiguration.ratingCategoryTwo)
    pushFieldConfiguration("RatingCategoryTwo");
  if (reviewConfiguration.ratingCategoryThree)
    pushFieldConfiguration("RatingCategoryThree");

  return (
    <div className="px-3">
      {!isSubmited && (
        <DynamicForm
          formLeadConfiguration={formConfiguration}
          title={title}
          onSubmit={onSubmitReview}
        />
      )}
      {isSubmited && (
        <>
          <h1 className="card-title text-center bullseye-red mb-5">
            Thank you!
          </h1>
          <span className="oi oi-check"></span>
          <span className="card-text text-center">
            Your review information has been received. It will be posted
            shortly.
          </span>
        </>
      )}
    </div>
  );
};

export default WriteReview;
