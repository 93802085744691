import React from "react";
import "./reviews.scss";
const RatingService = ({ name, rating }) => {
  var rows = [];
  for (var i = 1; i < 6; i++) {
    rows.push(
      <div
        key={i}
        className={"progressBar" + (rating >= i ? " fill" : "")}
      ></div>
    );
  }

  return (
    <div className="d-flex flex-column mb-3">
      <div className="mb-1">{name}</div>
      <div className="d-flex">{rows}</div>
    </div>
  );
};

export default RatingService;
