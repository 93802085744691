import { useEffect, useState } from "react";
import CheckEmailForm from "./checkEmailForm";
import LoginForm from "./loginForm";
import RegistrationForm from "./registrationForm";
import { useTranslation } from "react-i18next";

/**
 * Authentication form component.
 *
 * Makes a Bullseye API call to authenticate an existing user.
 *
 * @param {Object} props
 * @param props.onAuthenticated Callback function if a user has been authenticated.
 * @param props.onNewUser Callback function if a new user has been submitted.
 * @returns JSX
 */
const Auth = (props) => {
  const [action, setAction] = useState("check_email");
  const [userData, setUserData] = useState({
    email: undefined,
    password: undefined,
    name: undefined,
  });

  useEffect(() => {
    action === 'login_complete' && props.onAuthenticated(userData);
    action === 'registration_complete' && props.onNewUser(userData);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  const { t } = useTranslation();

  return (
    <div className="auth mb-3">
      <div className="bullseye-red h4 mt-3 mb-4 text-center">
        { t("auth.title") }
      </div>
      {action === "check_email" && (
        <CheckEmailForm
          onExistingUser={(input) => {
            setAction("login");
            setUserData({...userData, ...input});
          }}
          onNewUser={(input) => {
            setAction("registration");
            setUserData({...userData, ...input});
          }}
        />
      )}
      {action === "login" && (
        <LoginForm
          data={userData}
          onLogin={(input) => {
            setUserData({...userData, ...input});
            setAction("login_complete");
          }}
        />
      )}
      {action === "registration" && (
        <RegistrationForm
          data={userData}
          onSubmit={(input) => {
            setUserData({...userData, ...input});
            setAction("registration_complete");
          }}
        />
      )}
    </div>
  );
};

export default Auth;
