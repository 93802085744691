import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import ErrorDisplay from "../errorDisplay/errorDisplay";
import Label from "../label/label";
import "./dateTimeRange.scss";
import { useTranslation } from "react-i18next";

const DateTimeRange = ({
  name,
  label,
  compact,
  small,
  errors,
  register,
  watch,
  onChange,
  required,
  validations,
  labelNote,
}) => {
  const startDate = watch("startDate");
  const startTime = watch("startTime");
  const endDate = watch("endDate");
  const endTime = watch("endTime");

  const { t } = useTranslation();

  return (
    <div className="form-group row datetime-range">
      {labelNote && (
        <p className="col-md-8 offset-md-4 label-note hidden">
          <em>{labelNote}</em>
        </p>
      )}
      {!compact && <Label htmlFor={name} label={label} required={required} />}
      <div className="col-12 col-sm-8 d-flex flex-column flex-sm-row">
        <div>
          <input
            id="startDate"
            type="date"
            name="startDate"
            min={new Date().toISOString().split("T")[0]}
            required
            className={`form-control mr-2 mb-2 ${small ? "form-control-sm" : ""} ${errors["startDate"] ? "is-invalid" : ""} `}
            ref={register(validations)}
          />
          <input 
            type="time" 
            id="startTime"
            name="startTime"
            ref={register(validations)}
            className={` form-control form-control-sm time ${errors["startTime"] ? "is-invalid" : ""} `}
            pattern="[0-9]{2}:[0-9]{2}"
            placeholder="hh:mm"
          />
          {(errors["startDate"] || errors["startTime"]) && (
            <ErrorDisplay
              errorMessage={ 
                errors["startDate"] 
                  ? errors["startDate"].message
                  : errors["startTime"].message 
              }
              isAllCols={true}
            />
          )}
        </div>
        <div className="p-2">to</div>
        <div>
          <input
              id="endDate"
              type="date"
              name="endDate"
              className={`form-control mr-2 mb-2 ${small ? "form-control-sm" : ""} ${
                errors["endDate"] ? "is-invalid" : ""
                } `}
              min={new Date().toISOString().split("T")[0]}
              ref={register({
                validate: (val) => {
                  if (!val) return validations.required;
                  if (`${startDate}T${startTime}` > `${val}T${endTime}`) {
                    return "The end date must be greater than the start date";
                  }
                  if (val < new Date().toISOString().split("T")[0]) {
                    return t('formLead.errorEndDatePast');
                  }
                }
              })}
            />
          <input 
            type="time" 
            id="endTime"
            name="endTime"
            className={`form-control form-control-sm time ${
              errors["endTime"] ? "is-invalid" : ""
            }`}
            pattern="[0-9]{2}:[0-9]{2}"
            placeholder="hh:mm"
            ref={register({
              validate: (val) =>
                !val
                  ? validations.required
                  : `${startDate}T${startTime}` < `${endDate}T${val}` || "The end date must be greater than the start date",
            })}
          />
          {(errors["endDate"] || errors["endTime"]) && (
            <ErrorDisplay
              errorMessage={ 
                errors["endDate"] 
                  ? errors["endDate"].message
                  : errors["endTime"].message 
              }
              isAllCols={true}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DateTimeRange;