import React, { useEffect, useState, useCallback, useContext } from "react";
import { useDoSearch2 } from "../../store/doSearch2.state";
import LandingPageContent from "../landingPageContent";

import { COUNTRIES_LIST_IDS } from "../../utils/constants";
import { CONGIF_PRODUCTION, CONFIG_STAGING } from "../../utils/configConstants";

import SEO from "../seo";
import { useLocation } from "../../store/location.state";

import BreadcrumbMenu from "../breadcrumbMenu";
import BullseyeLoader from "../bullseyeLoader";
import { useParams } from "react-router-dom";

import { useTracking } from "react-tracking";
import LanguageSwitcher from "../languageSwitcher/languageSwitcher";
import { useTranslation } from "react-i18next";
import { BullseyeContext } from "../../app2";

const LandingPage = () => {
  const { t } = useTranslation();
  const { landingPageConfiguration, locatorConfiguration } = useContext(BullseyeContext);

  /* Parameters from URL */
  const urlQueryString = window.location.search;
  const urlQueryParams = new URLSearchParams(urlQueryString);
  const distanceQS = urlQueryParams.get("distance");
  const urlReturn = urlQueryParams.get("urlR");
  const urlParams = useParams();

  const isPreviewing = urlQueryParams.get('preview');

  const [locationState, getAttributeValue] = useLocation(
    urlParams.location,
    null,
    landingPageConfiguration.landingPageId,
    locatorConfiguration.clientId,
    locatorConfiguration.apiKey
  );
  const locationData = locationState.location;

  /** configuration por nearbyLocation */
  const [searchParameters, setSearchParameters] = useState({
    countryId: null,
    latitude: null,
    longitude: null,
    state: null,
    city: null,
    pageSize: 5,
    configClientSearch: {
      apiKey: locatorConfiguration.apiKey,
      clientId: locatorConfiguration.clientId,
    },
  });

  const configForm =
    process.env.REACT_APP_ENVIROMENT !== "production"
      ? { ...CONFIG_STAGING }
      : { ...CONGIF_PRODUCTION };

  // Look for neaby locations
  useEffect(() => {
    if (locationData.Latitude && locationData.Longitude) {
      setSearchParameters({
        ...searchParameters,
        countryId: COUNTRIES_LIST_IDS.CANADA,
        state: null,
        city: null,
        latitude: locationData.Latitude,
        longitude: locationData.Longitude,
        radius: 5000,
        pageSize: 4,
        categoriesIDs: configForm.CATEGORY_LEGION_REMEMBRANCE_DAY_LOCATION, // location category Legion Branch Location
        configClientSearch: {
          apiKey: locatorConfiguration.apiKey,
          clientId: locatorConfiguration.clientId,
        },
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationData, locatorConfiguration]);

  const doSearch2State = useDoSearch2(
    searchParameters,
    useCallback(() => {}, [])
  );

  const { Track } = useTracking({
    page: "listMapLocator",
    searchReqLogId: doSearch2State.doSearch2SearchReqLogId,
  });

  return locationState.loading ? (
    <BullseyeLoader />
  ) : (
    <>
      <Track>
        <div className="container-fluid container-lg">
          <SEO
            seoTitle={locationData.SEO.SEOTitle}
            seoMetaDescription={locationData.SEO.MetaDescription}
          />
          <LanguageSwitcher />

          {!isPreviewing && <div className="card mt-3">
            <BreadcrumbMenu
              items={[
                {
                  url: `${
                    urlReturn ? urlReturn : process.env.REACT_APP_PUBLIC_LOCATOR
                  }${urlQueryString}`,
                  displayText: t("landingPage.breadcrumLocator"),
                  isExternalLink: true,
                },
                { displayText: locationData.Name },
              ]}
            />
          </div>}

          <LandingPageContent 
            landingPageConfiguration={landingPageConfiguration}
            locatorConfiguration={locatorConfiguration}
            locationId={urlParams.location}
            distanceQS={distanceQS}
          />
          <div className="row">
            <div className="col-12">
              <hr></hr>
            </div>
          </div>
        </div>
      </Track>
    </>
  );
};

export default LandingPage;
