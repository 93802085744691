import React from "react";
import StartRating from "./startRating";
import RatingService from "./ratingService";
import PropTypes from "prop-types";

const CardRating = ({
  author_name,
  relative_time_description,
  rating,
  text,
  services,
  reviewConfiguration,
}) => {
  return (
    <div className="border-bottom">
      <div className="my-3 d-flex flex-column flex-md-row">
        <div className="pr-2 w-100 w-md-75">
          <h5 className="mb-0">
            {reviewConfiguration.reviewerName && <>{author_name}</>}

            <small className="ml-2 text-muted">
              {relative_time_description}
            </small>
          </h5>
          {reviewConfiguration.overallRating && <StartRating rating={rating} />}

          <p>{text}</p>
        </div>

        {services && (
          <div style={{ minWidth: 160 }}>
            {services
              .filter((x) => x.rating)
              .map((e, index) => {
                return (
                  <RatingService key={index} name={e.name} rating={e.rating} />
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};
CardRating.propTypes = {
  /** Author name of the review */
  author_name: PropTypes.string.isRequired,

  /** description of the date the review was made */
  relative_time_description: PropTypes.string.isRequired,

  /** rating number that was given in the review */
  rating: PropTypes.number.isRequired,
  /** Text description of the review */
  text: PropTypes.string,
  /** Array of the rating of the services */
  services: PropTypes.arrayOf(
    PropTypes.shape({
      /** name of the service */
      name: PropTypes.string.isRequired,
      /** rating of the service */
      rating: PropTypes.number.isRequired,
    })
  ),
};
export default CardRating;
