import axios from "axios";
import * as Sentry from "@sentry/react";

axios.defaults.baseURL = process.env.REACT_APP_URL_API;

axios.interceptors.response.use(null, (error) => {
  Sentry.captureException(error);

  // eslint-disable-next-line no-unused-vars
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  // if (!expectedError) {
  //   console.log("Unexpected Service error");
  //   //logger.log(error);
  //   window.location.pathname = "/error/unexpected";
  // } else if (error.response.status === 404) {
  //   console.log("Error: ", error);
  //   // toast.error("Location not found");
  //   window.location.pathname = "/error/not-found";
  // } else if (error.response.status === 401) {
  //   console.log("Error: ", error);
  //   toast.error("Unauthorized");
  // }
  console.log("Error request api: ", error);
  return; //Promise.reject(error);
});

export function setSecurityParameters(clientId, apiKey) {
  if (!clientId || !apiKey) return;
  axios.defaults.params = {};
  axios.defaults.params["ClientId"] = clientId;
  axios.defaults.params["ApiKey"] = apiKey;
}

export function setSecurityParameters2(clientId, apiKey) {
  axios.defaults.params = {};
  axios.defaults.params["ClientId"] = clientId
    ? clientId
    : process.env.REACT_APP_CLIENT_ID;
  axios.defaults.params["ApiKey"] = apiKey
    ? apiKey
    : process.env.REACT_APP_API_SEARCH_KEY;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setSecurityParameters,
  setSecurityParameters2,
};
