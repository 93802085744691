import React, { useState, useEffect } from "react";
import "./googleMap.scss";
import InfoMarker from "./infoMarker";

import { SEARCH_RES_LOG_TYPE, EVENT_LOG_TYPE } from "../../utils/constants";
import { useTracking } from "react-tracking";

import PropTypes from "prop-types";

/**
 * Comnponent to draw a pin location on the map
 */
const GoogleMapMarker = ({
  text,
  pin,
  marker,
  onClick,
  showInfoWindow,
  showLandingPageLink,
}) => {
  const { trackEvent } = useTracking();
  const [show, setShow] = useState(showInfoWindow);
  useEffect(() => {
    setShow(showInfoWindow);
  }, [showInfoWindow]);
  return (
    <>
      <div
        className="pin"
        onClick={() => {
          trackEvent({
            eventType: EVENT_LOG_TYPE.logSearch,
            locationId: marker.Id,
            responseType: SEARCH_RES_LOG_TYPE.locationMapClicked,
          });
          setShow(true);
          if (onClick) {
            onClick(marker);
          }
        }}
      >
        <img id={`pin-img-${marker.Id}`} src={pin} alt="pin" />

        {text && <p>{text}</p>}
      </div>
      {show && (
        <InfoMarker
          marker={marker}
          showLandingPageLink={showLandingPageLink}
          onClose={() => setShow(false)}
        />
      )}
    </>
  );
};

GoogleMapMarker.propTypes = {
  /** Text or label to display on the map pin */
  text: PropTypes.string,
  /**  URL image pin to display the map */
  pin: PropTypes.string.isRequired,
  /** Location Information with at least the following attributes */
  marker:
    /** Atributes per item on the location list */
    PropTypes.shape({
      /** location Id */
      Id: PropTypes.number.isRequired,
      /** coords of location */
      Latitude: PropTypes.number.isRequired,
      Longitude: PropTypes.number.isRequired,
      /** name of the location */
      Name: PropTypes.string.isRequired,
      /** address of the location */
      Address1: PropTypes.string.isRequired,
      /** city of the address */
      City: PropTypes.string.isRequired,
      /** state of city */
      State: PropTypes.string,
      /** postal code of the location */
      PostCode: PropTypes.string,
      /** country of this location */
      Country: PropTypes.string,
      /** url of the lading page detail of this location */
      URL: PropTypes.string,
    }).isRequired,
  /** function to on click on one item to the list will be selected into the parent */
  onClick: PropTypes.func,
  /** flag to display the InfoWindow popup or not */
  showInfoWindow: PropTypes.bool.isRequired,
  /** flag to show on the InfoWindow the name as a link or not, this will be used to redirect to a Location landing page  */
  showLandingPageLink: PropTypes.bool.isRequired,
};

export default GoogleMapMarker;
