import React from "react";
import { useTranslation, Trans } from "react-i18next";
import "./aboutLegion.scss";
const AboutLegion = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className="bullseye-red h4">{t("aboutLegion.aboutRemembrance")}</div>
      <Trans i18nKey="aboutLegion.info">
        <p>
          The Royal Canadian Legion is committed to ensuring Canadians never
          forget.
        </p>
        <p>
          In today's fast-paced world, it can be easy to take our way of life
          granted, and forget what so many men and women sacrificed for our
          freedoms. Remembrance initiative remind us to take to honour those who
          have dedicted their lives to serve our nation.
        </p>
      </Trans>

      <div className="d-flex flex-column flex-sm-row">
        <div className="card p-2 mr-md-2 AboutLegion__card d-block">
          <img
            className="card-img-top"
            src="/images/campaign/importance.jpg"
            alt="importance"
          />
          <div className="card-body">
            <strong>{t("aboutLegion.campaign2Tittle")}</strong>
            <p>{t("aboutLegion.campaign2Description")}</p>

            <a
              href={t("aboutLegion.campaign2Link")}
              className={`btn btn-danger`}
              target="_blank"
              rel="noreferrer"
            >
              {t("aboutLegion.button_explore")}
            </a>
          </div>
        </div>
        <div className="card p-2 mr-md-2 AboutLegion__card d-block">
          <img
            className="card-img-top"
            src="/images/campaign/poppy campaign.jpg"
            alt="camaign"
          />
          <div className="card-body">
            <strong>{t("aboutLegion.campaign1Tittle")}</strong>
            <p>{t("aboutLegion.campaign1Description")}</p>
            <a
              href={t("aboutLegion.campaign1Link")}
              target="_blank"
              rel="noreferrer"
              className={`btn btn-danger ${
                i18n.language.includes("fr") ? "mt-4" : ""
              } `}
            >
              {t("aboutLegion.button_explore")}
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutLegion;
